<template>
<section class="price">
   <div class="banner">
      <p :class="$i18n.locale == 'en' ? 'wrap' : 'nowrap'">{{ $t("PC.Product.POMA.sgfs7b") }}</p>
      <a :href="pomaUrl">{{ $t("PC.Product.POMA.rms9wl") }}</a>
   </div>
   <div class="main">
      <div class="pm">
         <div class="box b1">
            <div class="setmeal">
               <span>{{ $t('PC.Product.POMA.Price.biao-zhun-ban-basic') }}</span>
            </div>
            <div class="limit">
               <span>{{ $t('PC.Product.POMA.Price.cpu-he-shu-1') }}：1 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.cun-chu-kong-jian-3g') }} ：3G<i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.tong-shi-xun-lian-ren-wu-shu-1') }}：1 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.shou-hou-fu-wu') }} <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>GPU <i class="iconfont iconshanchu_bianqian"></i></span>
            </div>
            <div class="set_price">
               <span><i>￥</i>89<i>{{ $t('PC.Product.POMA.Price.yue') }}</i></span>
            </div>
         </div>
         <div class="box b2">
            <div class="setmeal">
               <span>{{ $t('PC.Product.POMA.Price.zuan-shi-ban-diamond') }}</span>
            </div>
            <div class="limit">
               <span>{{ $t('PC.Product.POMA.Price.cpu-he-shu-1') }}：5 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.cun-chu-kong-jian-3g') }}：7G <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.tong-shi-xun-lian-ren-wu-shu-1') }}：4 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.shou-hou-fu-wu') }} <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>GPU <i class="iconfont iconshanchu_bianqian"></i></span>
            </div>
            <div class="set_price">
               <span><i>￥</i>158<i>{{ $t('PC.Product.POMA.Price.yue') }}</i></span>
            </div>
         </div>
         <div class="box b3">
            <div class="setmeal">
               <span>{{ $t('PC.Product.POMA.Price.zhi-zun-ban-premium') }}</span>
               <!-- <div class="discount">
                  <span>年付8折</span>
               </div> -->
            </div>
            <div class="limit">
               <span>{{ $t('PC.Product.POMA.Price.cpu-he-shu-1') }}：7 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.cun-chu-kong-jian-3g') }}：15G <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.tong-shi-xun-lian-ren-wu-shu-1') }}：6 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>{{ $t('PC.Product.POMA.Price.shou-hou-fu-wu') }} <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
               <span>GPU <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            </div>
            <div class="set_price">
               <span> <i>￥</i>365<i class="yue">{{ $t('PC.Product.POMA.Price.yue') }}</i></span>
            </div>
         </div>
      </div>
      <FooterC></FooterC>
   </div>
</section>
</template>

<script>
import FooterC from "./components/FooterC";
export default {
   components: {
      FooterC,
   },
   data() {
      return {
         pomaUrl: Global.POMA_URL
      }
   },
};
</script>

<style lang="scss" scoped>
.price {
   margin-top: 70Px;
   background-color: #f1f2f6;

   .banner {
      width: 100%;
      display: block;
      position: absolute;
      left: 50%;
      top: 70Px;
      transform: translate(-50%, 0);
      overflow: hidden;
      background-image: url("../../assets/zh/images/banner@2x.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 1920Px;
      height: 440Px;

      p {
         height: 56Px;
         font-size: 40Px;
         color: #ffffff;
         line-height: 56Px;
         position: absolute;
         top: 136Px;
         left: 480Px;
         letter-spacing: 4Px;
      }

      .wrap {
         word-wrap: normal;
         width: 600Px;
         top: 106Px;
      }

      a {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 236Px;
         left: 480Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         border-radius: 2Px;
         border: 1Px solid #fff;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;

         &:hover {
            cursor: pointer;
         }
      }

      .price {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 236Px;
         left: 617Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         background: transparent;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;
         border: 1Px solid #ffffff;

         &:hover {
            cursor: pointer;
         }
      }
   }

   .main {
      position: absolute;
      top: 510Px;
      width: 100%;
      height: 593Px;

      .pm {
         margin: 0 auto;
         height: 593Px;
         width: auto;
         display: flex;
         justify-content: center;
         align-items: center;

         .box {
            width: 300Px;
            height: 449Px;
            border-radius: 8Px;
            overflow: hidden;
            border: 1Px solid #e1e1e1;

            .setmeal {
               height: 83Px;
               background-position: center center;
               background-size: 110%;
               background-repeat: no-repeat;
               position: relative;

               &>span {
                  display: inline-block;
                  width: 100%;
                  font-size: 24Px;
                  color: #fff;
                  line-height: 83Px;
                  text-align: center;
               }

               .discount {
                  width: 147Px;
                  height: 23Px;
                  background-color: #fc7750;
                  box-shadow: 0Px 2Px 9Px 0Px #c7ab8a;
                  transform: rotate(-30deg);
                  position: absolute;
                  top: 0;
                  left: -30Px;

                  span {
                     height: 23Px;
                     display: inline-block;
                     line-height: 23Px;
                     font-size: 12Px;
                     font-weight: 500;
                     color: #fff;
                     text-align: left;
                     padding-left: 36Px;
                  }
               }
            }

            .limit {
               border-bottom: 1Px solid #e1e1e1;
               height: 218Px;
               margin: 0 20Px;
               padding: 14Px 21Px;
               box-sizing: border-box;

               span {
                  display: inline-block;
                  width: 100%;
                  padding: 8Px 0;
                  font-size: 14Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  color: #333;
                  line-height: 20Px;

                  i {
                     float: right;
                  }
               }
            }

            .set_price {
               width: 100%;
               font-size: 46Px;
               color: #333;
               line-height: 65Px;
               padding: 35Px 0 50Px;
               text-align: center;

               i {
                  height: 20Px;
                  font-size: 16Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #212121;
                  line-height: 20Px;
               }

               .yue {
                  font-size: 14Px;
               }
            }
         }

         .b1 {
            .setmeal {
               background-image: url("../../assets/zh/images/dingjia_img1@2x.png");
            }
         }

         .b2 {
            margin: 0 64Px;

            .setmeal {
               background-image: url("../../assets/zh/images/dingjia_img2@2x.png");

               span {
                  color: #875B06;
               }
            }

         }

         .b3 {
            .setmeal {
               background-image: url("../../assets/zh/images/dingjia_img3@2x.png");

               span {
                  color: #836029;
               }
            }
         }
      }
   }
}
</style>
